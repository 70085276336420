

interface HeaderLink {
  Url: string;
  Label: string;
}

const state = reactive<{
  headerLink: HeaderLink | null
}>({
  headerLink: null
})

const headerLink = computed((): HeaderLink | null => state.headerLink)

const setHeaderLink = (link: HeaderLink | null) => {
  state.headerLink = link
}

const route = useRoute()
const routesWithoutHeaderLink = ['queue', 'track-damage', 'parked-transport', 'non-invoiced-orders']

watch(() => route.path, () => {
  if (headerLink.value?.Url.includes('undefined')) {
    console.warn('headerLink contains undefined value')
  }

  if (routesWithoutHeaderLink.find((r) => route.path.includes(r))) {
    setHeaderLink(null)
  }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHeaderStore = () => ({
  headerLink,
  setHeaderLink
})
